<template src="./VaccinationsList.html">
</template>
<script>


    export default {
        name: "VaccinationsList",
        components: {
        },
        created() {
            this.getVaccinations();
        },

        computed: {
            showAdd() {
                return (this.vaccinations.filter(x => x.status != this.$status.rejected).length >= 4);
            }
        },

        data: () => ({
            totalPages: 0,
            pageNo: 1,
            headers: [
                { text: "نوع التطعيم", value: "vaccineName" },
                { text: "البلدية", value: "municipalityName" },
                { text: "المركز", value: "healthCenterName" },
                { text: "تاريخ التطعيم", value: "vaccinationDate" },
                { text: "الحالة", value: "status" },
            ],
            vaccinations: [],

            msg: "في حالة رفض تطعيمة، ارجوا اعادة تحميل صورة واضحة لبطاقة التطعيم او زيارة المركز الصحي المعتمد"
        }),
        methods: {
            getVaccinations() {
                this.$http.getVaccinations(this.pageNo)
                    .then(res => {
                        let data = res.data;
                        this.vaccinations = data.vaccinations;
                        this.totalPages = data.totalPages;
                        let count = this.vaccinations.filter(x => x.status != this.$status.rejected).length;
                        console.log(count)
                    })
                    .catch(err => {
                        this.$store.commit(
                            "snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        )
                    });
            },

            pageChanged(pageNo) {
                this.pageNo = pageNo;
                this.getVaccinations();
            },

            
        },     
    };
</script>

<style lang="scss">

    .mobile-filter {
        height: 130px;
        flex-wrap: wrap;
    }

    .v-data-table-header-mobile {
        display: none;
    }
</style>